import './App.css';
import HomePage from './pages/HomePage';
import ApplyJob from './pages/ApplyJob';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ContactUsPage from './pages/ContactUsPage';
import LearnMore from './pages/LearnMore';
import ProductService from './pages/ProductService';
import RecruitJob from './pages/RecruitJob';
import PoleStar from './pages/PoleStar';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/applyJob/:id" component={ApplyJob} exact />

        <Route path="/contactus" component={ContactUsPage} exact />
        <Route path="/about" component={LearnMore} exact />
        <Route path="/service" component={ProductService} exact />
        <Route path="/recruit" component={RecruitJob} exact />
        <Route path="/polestar" component={PoleStar} exact />
      </Switch>{' '}
    </Router>
  );
}

export default App;
