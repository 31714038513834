import React, { Component } from 'react';
import SingleMolecule from './SingleMolecule/SingleMolecule';

let w = window.innerWidth;
let h = window.innerHeight;
export default class LeaderLGe extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: '-2', width: `${w}px`, height: `${h}px` }}>
        {/* <div> */}
        <SingleMolecule
          style={{
            width: '308px',
            height: '234px',
            top: '200px',
            left: '130px',
            transform: 'scale(2)',
            position: 'absolute',
          }}
        />
        <SingleMolecule
          style={{
            width: '308px',
            height: '234px',
            bottom: '130px',
            left: '40%',
            transform: 'scale(1.5)',
            position: 'absolute',
          }}
        />
        {/* </div> */}
      </div>
    );
  }
}
