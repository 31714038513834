import React, { Component } from 'react';
import styles from './index.module.less';
// import footerLogo from '../../images/footer_logo.png';
export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className={styles.bottom}>
          <div
            className={styles.bottom_banner}
            style={{ display: this.props.currentPageBottom === 'polestar' ? 'none' : 'flex' }}
          >
            {/* {this.props.currentPageBottom} */}
            <div onClick={() => this.props.clickFooterIndex()} style={{ cursor: 'pointer' }}>
              回到首页
            </div>

            <div onClick={() => this.props.clickHeaderService()} style={{ cursor: 'pointer' }}>
              产品服务
            </div>
            {/* <div onClick={() => this.props.clickHeaderJob()} style={{ cursor: 'pointer' }}>
              工作机会
               <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.mokahr.com/social-recruitment/cwdata/44640"
              >
                工作机会
              </a>
            </div> */}
          </div>

          <div
            style={{
              width: '90%',
              margin: '0 auto',
              paddingTop: this.props.currentPageBottom === 'polestar' ? '40px' : '0',
            }}
          >
            {/* <img src={footerLogo} alt="" className={styles.center_title} /> */}
          </div>
          <div className={styles.center}>
            <div className={styles.center_middle}>
              <div className={styles.center_middle_1}>
                汇智康行数据科技有限公司是由中央直属企业中电数据和药明康德集团合资成立的国资控股企业。提供全产业链、一体化健康医疗大数据分析产品和解决方案，致力于进一步推动医疗产业的创新发展并造福更多的中国患者。
              </div>
              <div className={styles.center_middle_2}>
                <div style={{ fontWeight: '600' }}>业务咨询与客户服务</div>
                <div style={{ fontWeight: '400', fontFamily: 'PingFangSC-Regular, PingFang SC' }}>
                  座机 010-52871626
                </div>
                <div style={{ fontWeight: '400', fontFamily: 'PingFangSC-Regular, PingFang SC' }}>
                  邮箱 info@cwdata.com
                </div>
              </div>
              <div className={styles.center_middle_2}>
                {/* <div style={{ fontWeight: '600' }}>办公地址</div>
                <div>北京市 东城区 王府井大街219号</div>
                <div>淘汇新天5层</div> */}
              </div>
            </div>

            <div className={styles.center_right}>
              <div className={styles.code} />
              <div className={styles.code_text}>公众号</div>
            </div>
          </div>

          <div className={styles.center_iphone}>
            <div className={styles.center_content}>
              <div className={styles.center_left}>
                <div>{/* <img src={footerLogo} alt="" className={styles.center_title_iphone} /> */}</div>
                <div className={styles.center_middle_1}>
                  汇智康行数据科技有限公司是由中央直属企业中电数据和药明康德集团合资成立的国资控股企业。提供全产业链、一体化健康医疗大数据分析产品和解决方案，致力于进一步推动医疗产业的创新发展并造福更多的中国患者。
                </div>
              </div>
              <div className={styles.center_right}>
                <div className={styles.code} />
                <div className={styles.code_text}>公众号</div>
              </div>
            </div>

            <div className={styles.center_middle}>
              <div className={styles.center_middle_2}>
                <div style={{ fontWeight: '600' }}>业务咨询与客户服务</div>
                <div style={{ fontWeight: '400', fontFamily: 'PingFangSC-Regular, PingFang SC' }}>
                  座机 010-52871626
                </div>
                <div style={{ fontWeight: '400', fontFamily: 'PingFangSC-Regular, PingFang SC' }}>
                  邮箱 info@cwdata.com
                </div>
              </div>
              <div className={styles.center_middle_2}>
                {/* <div>办公地址</div>
                <div>北京市 东城区 王府井大街219号</div>
                <div>淘汇新天5层</div> */}
              </div>
            </div>
          </div>

          {/* <div className={styles.bottom_bottomText}>
            Copyright © 2021 汇智康行&nbsp;
            <a
              href="https://beian.miit.gov.cn/"
              style={{ color: '#ffffff', textDecoration: 'none', cursor: 'pointer' }}
            >
              蜀ICP备19030835号-1
            </a>
            &nbsp; (川)-经营性-2020-0038
          </div> */}
        </div>
      </div>
    );
  }
}

export default Footer;
