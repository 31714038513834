import React from 'react';
// import { withRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import Header from './../../components/Header/';
import ReactFullpage from '@fullpage/react-fullpage';
import Page1 from './../Page1/';
import Page2 from './../Page2/';

import IntroPage from './../IntroPage/';
import page from './../../utils/defaultInfo';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import lsfMask from './../../images/pageOne/lsfMask.png';
import lgMask from './../../images/pageOne/lgMask.png';
import lsfNormal from './../../images/pageOne/lsf@2x.png';
import lgNormal from './../../images/pageOne/lg@2x.png';
import classNames from 'classnames';
import 'animate.css';
import styles from './index.module.less';
// import PageFour from '../PageFour';
// import PageFive from '../PageFive';
// import Modal from '../../components/Modal';
import PhoneMenu from '../../components/PhoneMenu';

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showContactUsModal: false,
      deviceType: '', // 设备类型：phone（0-1024），pad（1025-1440），pc（1441-）
      pageNum: 0,
      currentPage: '',
      animateShow0: false, // 页面动画效果，默认为关闭，每次切换一个页面的时候先关闭再开启，以此触发动画效果
      animateShow1: false, // 页面动画效果，默认为关闭，每次切换一个页面的时候先关闭再开启，以此触发动画效果
      animateShow2: false, // 页面动画效果，默认为关闭，每次切换一个页面的时候先关闭再开启，以此触发动画效果
      animateShow3: false, // 页面动画效果，默认为关闭，每次切换一个页面的时候先关闭再开启，以此触发动画效果
      animateShow4: false, // 页面动画效果，默认为关闭，每次切换一个页面的时候先关闭再开启，以此触发动画效果
    };
  }
  contactUs = () => {
    this.setState({
      showContactUsModal: true,
    });
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    console.log('jumpToContactUsPage');
    this.props.history.push(`/contactus`);
  };
  // 产品服务
  jumpToServicePage = () => {
    console.log('jumpToServicePage');
    this.props.history.push(`/service`);
  };
  // 工作机会
  jumpToJobPage = () => {
    console.log('jumpToServicePage');
    // const w = window.open('about:blank');
    window.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      console.log('小于');
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  clickHeaderService = () => {
    this.setState(
      {
        currentPage: 'service',
      },
      () => {
        this.props.history.push(`/service`);
      },
    );
  };
  clickHeaderJob = () => {
    // this.setState(
    //   {
    //     currentPage: 'recruit',
    //   },
    //   () => {
    //     this.props.history.push(`/recruit`);
    //   },
    // );
  };
  clickGoBack = () => {
    this.props.history.push(`/`);
  };
  // 页面跳转
  // jumpTo = (path, subPath) => {
  //   this.props.history.push(`/${path}?id=${subPath}`);
  // };
  // --------------------------------------------- 页面 ------------------------------------------------
  render() {
    const {
      showContactUsModal,
      // phoneSettingShow,
      // pageNum,
      animateShow0,
      animateShow1,
      animateShow2,
      // animateShow3,
      // animateShow4,
    } = this.state;

    return (
      <div className={styles.homePage}>
        {/* //- Header固定放顶部 */}
        <div className="header" style={{ position: 'fixed', width: '100%', zIndex: '99' }}>
          <Header
            contactUs={this.contactUs}
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
            currentPage={this.state.currentPage}
          />
        </div>
        {/* {showContactUsModal && <Modal closeContactModal={this.closeContactModal} />} */}
        {showContactUsModal && (
          <PhoneMenu
            closePhoneMenuModal={this.closePhoneMenuModal}
            jumpToContactUsPage={this.jumpToContactUsPage}
            jumpToServicePage={this.jumpToServicePage}
            jumpToJobPage={this.jumpToJobPage}
          />
        )}

        {/* // -fullPage */}
        <ReactFullpage
          licenseKey="YOUR_KEY_HERE"
          scrollingSpeed={1000}
          onLeave={(index, nextIndex, direction) => {
            let onLeaveIndex = index.index; // 当前要离开的页面索引
            let nextPageIndex = nextIndex.index; // 即将跳转到的页面索引
            // 滚动前的回调函数
            if (onLeaveIndex === 0) {
              this.setState({ animateShow0: false, pageNum: nextPageIndex });
            }
            if (onLeaveIndex === 1) {
              this.setState({ animateShow1: false, pageNum: nextPageIndex });
            }
            if (onLeaveIndex === 2) {
              this.setState({ animateShow2: false, pageNum: nextPageIndex });
            }
            if (onLeaveIndex === 3) {
              this.setState({ animateShow3: false, pageNum: nextPageIndex });
            }
            if (onLeaveIndex === 4) {
              this.setState({ animateShow4: false, pageNum: nextPageIndex });
            }
          }}
          afterLoad={(anchorLink, index) => {
            if (index.index === 0) {
              this.setState({ animateShow0: true });
            }
            if (index.index === 1) {
              this.setState({ animateShow1: true });
            }
            if (index.index === 2) {
              this.setState({ animateShow2: true });
            }
            if (index.index === 3) {
              this.setState({ animateShow3: true });
            }
            if (index.index === 4) {
              this.setState({ animateShow4: true });
            }
          }}
          render={({ state, fullpageApi }) => {
            return (
              <div>
                <ReactFullpage.Wrapper>
                  <div className={classNames('section')}>
                    <IntroPage animateShow={animateShow0} clickNextPage={() => fullpageApi.moveSectionDown()} />
                  </div>
                  {/* <div className="section">
                    <Page1
                      pageInfo={page.pageOneInfo}
                      imgS={lsfMask}
                      imgN={lsfNormal}
                      animateShow={animateShow1}
                      pageNum={this.state.pageNum}
                      clickNextPage={() => fullpageApi.moveSectionDown()}
                      {...this.props}
                    />
                  </div>
                  <div className="section">
                    <Page2
                      pageInfo={page.pageTwoInfo}
                      imgS={lgMask}
                      imgN={lgNormal}
                      animateShow={animateShow2}
                      pageNum={this.state.pageNum}
                      // clickNextPage={() => fullpageApi.moveSectionDown()}
                      clickNextPage={() => {
                        fullpageApi.silentMoveTo(1, 0); // moveTo有动画，silentMoveTo没有动画效果直接跳转到第一页
                      }}
                      {...this.props}
                    />
                  </div> */}
                  {/* <div className="section">
                    <PageFour
                      animateShow={animateShow3}
                      pageNum={this.state.pageNum}
                      clickNextPage={() => fullpageApi.moveSectionDown()}
                    />
                  </div> */}
                  {/* <div className="section">
                    <PageFive
                      animateShow={animateShow4}
                      pageNum={this.state.pageNum}
                      clickNextPage={() => {
                        fullpageApi.silentMoveTo(1, 0); // moveTo有动画，silentMoveTo没有动画效果直接跳转到第一页
                      }}
                    />
                  </div> */}
                </ReactFullpage.Wrapper>
              </div>
            );
          }}
        />
      </div>
    );
  }
  // clickNextPage = (pageNum) => {
  //   this.setState({ pageNum });
  // };
}

export default HomePage;
