import React, { Component } from 'react';
import styles from './index.module.less';
import Header from './../../components/Header/';
import PhoneMenu from '../../components/PhoneMenu';
import Footer from '../../component/Footer/index';
import RecruitJobPart from './component/RecruitJobPart/index';
import EnvironmentPart from './component/EnvironmentPart/index';

class RecruitJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactUsModal: false,
      currentPage: 'recruit',
      tabData: [
        {
          title: '正在招聘',
          index: 0,
          active: true,
          view: <RecruitJobPart {...this.props} />,
        },
        {
          title: '环境待遇',
          index: 1,
          active: false,
          view: <EnvironmentPart />,
        },
      ],
    };
  }
  // tab切换
  tabChange = (index) => {
    const { tabData } = this.state;
    let newData = tabData.map((item, i) => {
      item.active = index === i ? true : false;
      return item;
    });
    this.setState({
      tabData: newData,
    });
  };
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      console.log('小于');
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  // clickHeaderService = () => {
  //   this.props.history.push(`/service`);
  // };
  clickGoBack = () => {
    this.props.history.push(`/`);
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    console.log('jumpToContactUsPage');
    this.props.history.push(`/contactus`);
  };
  // 产品服务
  jumpToServicePage = () => {
    console.log('jumpToServicePage');
    // this.setState({
    //   showContactUsModal: false,
    // });
    this.props.history.push(`/service`);
  };

  // clickHeaderJob = () => {
  //   this.props.history.push(`/recruit`);
  // };
  jumpToJobPage = () => {
    // this.props.history.push(`/recruit`);
    this.setState({
      showContactUsModal: false,
    });
  };

  clickHeaderService = () => {
    this.setState(
      {
        currentPage: 'service',
      },
      () => {
        this.props.history.push(`/service`);
      },
    );
  };
  clickHeaderJob = () => {};
  // footer
  clickFooterIndex = () => {
    this.props.history.push(`/`);
  };
  render() {
    const { tabData, showContactUsModal } = this.state;
    return (
      <div className={styles.pageJob} style={{ background: '#fff' }}>
        <div style={{ position: 'fixed', width: '100%', zIndex: '99' }}>
          <Header
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
            currentPage={this.state.currentPage}
          />
          {showContactUsModal && (
            <PhoneMenu
              closePhoneMenuModal={this.closePhoneMenuModal}
              jumpToContactUsPage={this.jumpToContactUsPage}
              jumpToServicePage={this.jumpToServicePage}
              jumpToJobPage={this.jumpToJobPage}
            />
          )}
        </div>
        {/* <div className={styles.header}>汇智康行</div> */}
        <div className={styles.title}>
          <div className={styles.text}>成为汇智康行的一员，让工作不止是一份工作</div>
          <div className={styles.text1}>
            当前，医药健康产业正步入前所未有的黄金时代。知识与数据交融，科技与医疗融合。
            我们是追求向上生长和充满抱负的年轻生命最优选择。把握历史机遇，参与其中，共同探索、创造、实现自我。
            我们充满热情，期待你的加入。
          </div>
        </div>
        {/* *****************tab切换标题渲染******************** */}
        <div className={styles.middle_banner}>
          {tabData.map((item, index) => (
            <div className={styles.tab_content} key={`tab${index}`}>
              <span
                onClick={this.tabChange.bind(this, index)}
                className={item.active ? styles.currently_selected : styles.tab_item}
              >
                {item.title}
              </span>
              {index !== tabData.length - 1 ? <span className={styles.tab_line}>|</span> : null}
            </div>
          ))}
        </div>
        {/* *****************tab切换后渲染对应组件******************** */}
        {tabData.map((item, index) => (
          <div key={`tab_content${index}`}>{item.active ? item.view : null}</div>
        ))}

        <div className={styles.tip}>找不到相关职位？直接投递简历至 jobs@cwdata.com</div>
        <Footer
          clickFooterIndex={this.clickFooterIndex}
          clickHeaderService={this.clickHeaderService}
          clickHeaderJob={this.clickHeaderJob}
        />
      </div>
    );
  }
}

export default RecruitJob;
