import React from 'react';
import ReactDOM from 'react-dom';
import './index.module.less';
import App from './App';

ReactDOM.render(
  <div>
    <App />
  </div>,
  document.getElementById('root'),
);
