import React from 'react';
import styles from './index.module.less';
// import {message} from 'antd';
import echarts from 'echarts/lib/echarts';
import '../../../node_modules/echarts/map/js/china';
// import geoJson from 'echarts/map/json/china.json';

const data = [
  { name: '合肥', value: 150 },
  { name: '天津西清', value: 150 },
  { name: '成都', value: 150 },
  { name: '杭州', value: 150 },
  { name: '福州', value: 150 },
  { name: '昆明', value: 150 },
  { name: '广州', value: 150 },
  { name: '长沙', value: 150 },
  { name: '海南', value: 150 },
  { name: '上饶', value: 150 },
];
const geoCoordMap = {
  合肥: [117.27, 31.86],
  天津西清: [117.2, 39.13],
  成都: [104.06, 30.67],
  杭州: [120.19, 30.26],
  福州: [119.3, 26.08],
  昆明: [102.73, 25.04],
  广州: [113.23, 23.16],
  长沙: [113, 28.21],
  海南: [110.35, 19.4],
  上饶: [117.27, 29.21],
};

const convertData = (data) => {
  const res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};

class EChartMapBars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSHowSize: false,
    };
    this.option1 = {
      backgroundColor: '#ffffff',
      textStyle: {
        color: '#000',
      },
      layoutCenter: ['48%', '40%'], // 距离左右，上下距离的百分比
      layoutSize: '100%', // map百分比大小
      // title: {
      //   text: '全国主要城市空气质量',
      //   subtext: 'data from PM25.in',
      //   sublink: 'http://www.pm25.in',
      //   left: 'center',
      //   textStyle: {
      //     color: '#fff',
      //   },
      // },
      tooltip: {
        trigger: 'item',
        formatter(params) {
          return params.name;
        },
      },
      legend: {
        orient: 'vertical',
        y: 'bottom',
        x: 'right',
        data: ['pm2.5'],
        textStyle: {
          color: '#fff',
          // color: '#000',
        },
      },
      geo: {
        map: 'china',
        label: {
          emphasis: {
            show: false,
          },
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: '#ECF1F2',
            borderColor: '#CEE7F0',
          },
          emphasis: {
            areaColor: '#ffffff',
          },
        },
      },
      series: [
        {
          // name: 'Top 5',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: convertData(data),
          symbolSize: 17,
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke',
          },
          hoverAnimation: true,
          label: {
            normal: {
              formatter: '{b}',
              position: 'right',
              show: true,
            },
          },
          itemStyle: {
            normal: {
              // color: '#',
              color: '#fff',
              shadowBlur: 20,
              shadowColor: '#00C3D2',
            },
          },
          zlevel: 1,
        },
      ],
    };

    this.option = {
      backgroundColor: '#ffffff',
      textStyle: {
        color: '#000',
      },

      layoutCenter: ['48%', '40%'], // 距离左右，上下距离的百分比
      layoutSize: '100%', // map百分比大小
      // title: {
      //   text: '全国主要城市空气质量',
      //   subtext: 'data from PM25.in',
      //   sublink: 'http://www.pm25.in',
      //   left: 'center',
      //   textStyle: {
      //     color: '#fff',
      //   },
      // },
      tooltip: {
        trigger: 'item',
        formatter(params) {
          return params.name;
        },
      },

      legend: {
        orient: 'vertical',
        y: 'bottom',
        x: 'right',
        data: ['pm2.5'],
        textStyle: {
          color: '#fff',
          // color: '#000',
        },
      },
      geo: {
        map: 'china',
        label: {
          emphasis: {
            show: false,
          },
        },
        roam: false,
        itemStyle: {
          normal: {
            areaColor: '#ECF1F2',
            borderColor: '#CEE7F0',

            // borderColor: '#FFAF29',
          },
          emphasis: {
            areaColor: '#ECF1F2',
          },
        },
      },
      series: [
        // {
        //   name: 'pm2.5',
        //   type: 'scatter',
        //   coordinateSystem: 'geo',
        //   data: convertData(data),
        //   symbolSize: this.state.isSHowSize ? 17 : 7,
        //   label: {
        //     normal: {
        //       formatter: '{b}',
        //       position: 'right',
        //       show: false,
        //     },
        //     emphasis: {
        //       show: true,
        //     },
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: '#C1E2EF',
        //     },
        //   },
        // },
        {
          // name: 'Top 5',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: convertData(data),
          symbolSize: 7,
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke',
          },
          hoverAnimation: true,
          label: {
            normal: {
              formatter: '{b}',
              position: 'right',
              show: true,
            },
          },
          itemStyle: {
            normal: {
              // color: '#',
              // color: '#FFAF29',
              color: '#fff',

              shadowBlur: 10,
              // shadowColor: '#00C3D2',
              shadowColor: '#FFAF29',
            },
          },
          zlevel: 1,
          // coordinateSystem: 'bmap',
        },
      ],
    };
  }

  componentDidMount() {
    this.bond();
    // if (document.documentElement.clientWidth >= 1024) {
    // }
    // if (this.props.option) {
    //     this.bond(this.props.option)
    // }

    window.addEventListener('resize', () => {
      if (this.props && this.props.option && this.props.option.eleId) {
        const dom = document.getElementById(this.props.option.eleId);
        if (dom) {
          const myChart = echarts.init(dom);

          myChart.resize();
        }
      }
    });
  }

  componentDidUpdate() {
    this.bond();

    // this.bond();
    // this.bond1();
    // if (this.props.option) {
    //     this.bond(this.props.option)
    // }
  }

  updateOption = (option) => {};

  bond1(option1) {
    // if (!option) return;
    const dom = document.getElementById('echartsMapId');
    if (!dom) return;
    const chart = echarts.init(dom);
    // this.updateOption(option);

    chart.setOption(this.option1);
  }

  bond(option) {
    // if (!option) return;
    const dom = document.getElementById('echartsMapId');
    if (!dom) return;
    const chart = echarts.init(dom);
    // this.updateOption(option);

    if (document.documentElement.clientWidth >= 1024) {
      this.option.series[0].symbolSize = 10;
    } else {
      this.option.series[0].symbolSize = 9;
    }

    chart.setOption(this.option);
  }

  render() {
    return <div id="echartsMapId" className={styles.echartsMapClass} />;
  }
}

export default EChartMapBars;
