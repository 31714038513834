import axios from 'axios';
import * as utils from 'axios/lib/utils';
// import { message } from 'antd';

// function createError(message, code, data) {
//   let error = new Error(`接口请求错误 message:${message}, code:${code}, result:${data}`);
//   if (code) {
//     error.code = code;
//   }
//   error.data = data;
//   return error;
// }

let axiosInstance = null;

function createAxiosInstance() {
    if (axiosInstance) {
        return axiosInstance;
    }
    axiosInstance = axios.create();
    // axiosInstance.defaults.baseURL = 'https://test.cwdata.com:6742';

    axiosInstance.defaults.baseURL = '/api/';
    axiosInstance.defaults.timeout = '15000';
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    return axiosInstance;
}

const request = async function(options) {
    const instance = createAxiosInstance();
    // instance.defaults.headers['x-token'] =
    //   'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIxIiwic3ViIjoiYWRtaW4iLCJpc3MiOiJjd2RhdGEiLCJpYXQiOjE2MDY3MDU3NjZ9.z5mwviNrsyyKxNm2PnW0CMCrghAgtnneOoZ0FSKS0tk';
    try {
        if (!options.data) {
            options.data = {};
        }
        // console.log('request headers', options);
        const response = await instance(options);
        // 处理业务错误
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('请求错误');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

utils.forEach(['delete', 'get', 'head', 'options'], function forEachMethodNoData(method) {
    request[method] = function(url, data, config) {
        return request(
            utils.merge(config || {}, {
                method,
                url,
                params: data,
            }),
        );
    };
});

utils.forEach(['post', 'put', 'patch'], function forEachMethodWithData(method) {
    request[method] = function(url, data, config) {
        return request(
            utils.merge(config || {}, {
                method,
                url,
                data,
            }),
        );
    };
});

export default request;