import React, { Component } from 'react';
import styles from './index.module.less';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionShow: false,
      selectedOption: this.props.placeholder,
      options: this.props.options,
    };
  }

  selectClick = () => {
    // 当点击select选择器时，创建dom透明弹出层，用于点击空白处收回select下拉项
    this.createLayer();
    this.setState({
      optionShow: true,
    });
  };

  // 选中某一项
  selected = (selected) => {
    const { options } = this.state;
    let selectedOption = '';
    let value = '';
    let type = '';
    if (options.length > 0) {
      let newOptions = options.map((item) => {
        if (item.value === selected.value) {
          item.isChecked = true;
          selectedOption = selected.label;
          value = selected.value;
          type = selected.type;
        } else {
          item.isChecked = false;
        }
        return item;
      });
      this.setState({
        selectedOption,
        options: newOptions,
        optionShow: false,
      });
    }
    this.removeLayer();
    this.props.onChange({ value, type });
  };

  // 动态创建透明层
  createLayer = () => {
    let layer = document.createElement('div');
    layer.id = 'transparentLayer';
    console.log('创建了浮层');
    layer.style.position = 'absolute';
    layer.style.width = '100%';
    layer.style.height = '100%';
    layer.style.zIndex = '9';
    let root = document.getElementById('root');
    root.insertBefore(layer, root.lastChild);
    layer.addEventListener('click', () => {
      this.closeLayer();
    });
  };

  // 删除创建的层
  removeLayer = () => {
    let layer = document.getElementById('transparentLayer');
    layer.parentNode.removeChild(layer);
  };

  closeLayer = () => {
    if (this.state.optionShow) {
      this.removeLayer();
      this.setState({
        optionShow: false,
      });
    }
  };

  render() {
    const { optionShow, selectedOption, options } = this.state;
    return (
      <div className={styles.select_box}>
        <div className={styles.selected_item} onClick={this.selectClick}>
          <span>{selectedOption}</span>
          {/* <i className={'iconfont' + ' ' + styles.icon_jiantou}>&#xe665;</i> */}
          <i className={`iconfont ${styles.icon_jiantou}`}>&#xe665;</i>
        </div>
        <div className={styles.options_item} style={{ display: optionShow ? 'block' : 'none' }}>
          {options.length > 0 &&
            options.map((item, index) => {
              return (
                <div key={item.value} onClick={this.selected.bind(this, item)} className={styles.option}>
                  {item.label}
                  <i
                    style={{ display: item.isChecked ? 'block' : 'none' }}
                    // className={'iconfont' + ' ' + styles.icon_duigou}
                    className={`iconfont ${styles.icon_duigou}`}
                  >
                    &#xe668;
                  </i>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default Select;
