import React, { Component } from 'react';
import styles from './index.module.less';
import closePng from './../../images/modalCmp/close_m.png';

export default class PhoneMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className={styles.PhoneMenuCmp}>
          <div className={styles.PhoneMenuBox}>
            <ul className={styles.PhoneMenuContent}>
              <li onClick={() => this.props.jumpToServicePage()} style={{ cursor: 'pointer' }}>
                产品服务
              </li>
              {/* <li onClick={() => this.props.jumpToJobPage()} style={{ cursor: 'pointer' }}>
                工作机会
              </li> */}
              <li onClick={() => this.props.jumpToContactUsPage()} style={{ cursor: 'pointer' }}>
                联系我们
              </li>
              <li className={styles.PhoneMenu_midLine} />
              <li>
                <img
                  className={styles.PhoneMenuCloseBtn}
                  src={closePng}
                  alt=""
                  onClick={() => this.props.closePhoneMenuModal()}
                  style={{ cursor: 'pointer' }}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
