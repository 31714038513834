import React from 'react';
import styles from './index.module.less';

import classNames from 'classnames';

function PageCircle(props) {
  return (
    <div className={classNames(styles.PageCircleCmp)} onClick={props.clickNextPage}>
      <div className={classNames(styles.goBackFirst)}>{props.children}</div>
      <div className={classNames(styles.circle)}>{props.pageNum}/2</div>
      <div className={classNames(styles.arrowDown)} />
      {/* {props.pageNum === 2 && <div>{props.children}</div>} */}
    </div>
  );
}

export default PageCircle;
