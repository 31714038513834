import React from 'react';
import PageCircle from './../../components/Pagecircle/';
import LeaderLSF from './../../components/BackgroundNumber/LeaderLSF';
import classNames from 'classnames';
import 'animate.css';

import styles from './index.module.less';
import LeaderLGe from '../../components/BackgroundMolecule/LeaderLGe';
class Page1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in: false,
      contentSmallShow: true,
      pageInfo: [],
      innerHeight: 0,
    };
  }
  componentDidMount() {
    this.setState({ in: true, innerHeight: window.innerHeight });
  }
  goAbout = () => {
    this.props.history.push(`/about`);
  };
  // .展示下一页
  clickNextPage() {
    this.props.clickNextPage(this.props.pageNum + 1);
  }
  // ------------------------- 页面 --------------------------
  render() {
    const { animateShow, pageInfo, imgS, imgN, pageNum } = this.props;
    const active = animateShow ? ['animate__animated', 'animate__fadeInUp'] : ['animate__animated', 'animate__fadeOut'];
    // const bgActive = animateShow
    //   ? ['animate__animated', 'animate__fadeInUp', styles.bgAnimate]
    //   : ['animate__animated', 'animate__fadeOut', styles.bgAnimate];
    const imgActive = animateShow
      ? ['animate__animated', 'animate__slideInUp']
      : ['animate__animated', 'animate__fadeOut'];
    // const btnMoreActive = animateShow
    //   ? ['animate__animated', 'animate__headShake']
    //   : ['animate__animated', 'animate__fadeOut'];
    // const blueBoxActive = animateShow ? ['animate__animated', 'animate'] : ['animate__animated', 'animate__fadeOut'];
    return (
      <div>
        {/* <div className={active.join(' ')}> */}
        {pageNum === 1 && (
          <div className={styles.backgroundAnimate}>
            <LeaderLSF />
          </div>
        )}
        {pageNum === 2 && (
          <div className={styles.backgroundAnimate}>
            <LeaderLGe />
          </div>
        )}
        {/* </div> */}

        {/* 首页-领导介绍页1 */}

        <div className={classNames(styles.PageOne)}>
          {/* //  - 手机端 */}
          <div
            className={classNames(styles.phoneDevc)}
            style={{ backgroundImage: `url( ${imgS})`, height: this.state.innerHeight }}
          >
            {/* 文字信息 */}

            <div className={classNames(styles.content)}>
              <div className={active.join(' ')}>
                <div className={classNames(styles.title)}>{pageInfo.title_small}</div>
                {this.state.contentSmallShow ? (
                  <div
                    className={classNames(styles.userContent)}
                    dangerouslySetInnerHTML={{ __html: pageInfo.content_small_part }}
                    onClick={() => {
                      this.setState({ contentSmallShow: false });
                    }}
                  />
                ) : (
                  <div className={classNames(styles.userContent)}>{pageInfo.content_small_all}</div>
                )}

                <div className={classNames(styles.line)} />
                <div className={classNames(styles.userName)}>{pageInfo.name}</div>
                <div className={classNames(styles.userTitle)}>{pageInfo.title1}</div>
                <div className={classNames(styles.userTitle)}>{pageInfo.title2}</div>

                {/* {pageNum === 1 && (
                  <div className={classNames(styles.btn_more)} onClick={this.goAbout.bind(this)}>
                    了解更多
                  </div>
                )} */}
              </div>
              <div className={active.join(' ')} style={{ marginTop: '0.55rem' }}>
                <PageCircle pageNum={pageNum} clickNextPage={() => this.clickNextPage()}>
                  {pageNum === 2 ? '回到第一页' : ''}
                </PageCircle>
              </div>
            </div>
          </div>

          {/* //  -pad端和pc端 */}
          <div className={classNames(styles.padAndPcDevc)}>
            <div style={{ position: 'fixed', right: 0 }}>
              <div className={classNames(styles.blueCube)} />
            </div>

            <PageCircle pageNum={pageNum} clickNextPage={() => this.clickNextPage()}>
              {pageNum === 2 ? '回到第一页' : ''}
            </PageCircle>
            <div style={{ display: 'flex', paddingTop: '70px' }}>
              {/* 左-文字信息 */}
              <div className={classNames(styles.leftOutter)}>
                <div className={classNames(styles.leftInner)}>
                  <div className={active.join(' ')}>
                    <div
                      className={classNames(styles.title)}
                      dangerouslySetInnerHTML={{ __html: pageInfo.title_normal }}
                    />
                    <div
                      className={classNames(styles.content)}
                      dangerouslySetInnerHTML={{ __html: pageInfo.content_normal }}
                    />
                    {/* {pageNum === 1 && (
                      <div className={classNames(styles.btn_more)} onClick={this.goAbout.bind(this)}>
                        了解更多
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className={classNames(styles.middleOutter)} />
              {/* 右-图像信息 */}
              <div className={classNames(styles.rightOutter)}>
                <div className={classNames(styles.rightInner)}>
                  {/* 人物图片 */}
                  <div className={classNames(styles.img)}>
                    {/* 图片 */}
                    <div className={imgActive.join(' ')}>
                      <div className={classNames(styles.pic)} style={{ backgroundImage: `url( ${imgN})` }} />
                      <div />
                      {/* 蓝色信息框 */}
                      <div className={active.join(' ')}>
                        <div className={classNames(styles.blueBox)}>
                          <div className={classNames(styles.blueBox_name)}>{pageInfo.name}</div>
                          <div className={classNames(styles.blueBox_title)}>
                            <div>{pageInfo.title1}</div>
                            <div>{pageInfo.title2}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page1;
