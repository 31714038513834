import React from 'react';
import styles from './index.module.less';

import classNames from 'classnames';

function SingleMolecule({ ...restProps }) {
  return (
    <div className={classNames(styles.singleMoleculeCmp)} {...restProps}>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
      <div className={styles.number}>1</div>
      <div className={styles.number}>0</div>
    </div>
  );
}

export default SingleMolecule;
