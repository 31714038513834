/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-unsafe */
import React, { Component } from 'react';
import styles from './index.module.less';
// import jobLogo from '../../../../images/1.png';
import Select from '../Select/index';
import request from '../../../../utils/request';
import ReactMarkdown from 'react-markdown';
import noData from '../../../../images/no_data.png';
class RecruitJobPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      jobExperiences: [],
      jobPositions: [],
      jobSites: [],
      jobTypes: [],
      selectDataForMobile: [],
      isMobile: false,
      jobIndex: '',
      jobsList: [],
      selectDataTypes: [], // 移动端筛选下拉框类型存储
      queryData: [
        {
          ids: [],
          type: 'job_position',
        },
        {
          ids: [],
          type: 'job_site',
        },
        {
          ids: [],
          type: 'job_experience',
        },
        {
          ids: [],
          type: 'job_type',
        },
      ],
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.getJobType();
    this.getJobList();
  }

  // 获取工作查询类型数据
  getJobType = async () => {
    let experiences = await request.get('/job-experiences');
    let sites = await request.get('/job-sites');
    let positions = await request.get('/job-positions');
    let types = await request.get('/job-types');

    let jobExperiences = this.handleData(experiences);
    let jobTypes = this.handleData(types);
    let jobSites = this.handleData(sites);
    let jobPositions = this.handleData(positions);

    this.setState({
      jobExperiences,
      jobSites,
      jobPositions,
      jobTypes,
    });
    // 拿到的数据处理成移动端需要的格式
    let dataForMobile = {
      jobExperiences,
      jobSites,
    };
    this.handleForMobile(dataForMobile);
  };

  // 为数据添加isChecked属性
  handleData = (source) => {
    if (Array.isArray(source)) {
      let arr = source.map((item) => {
        return { ...item, isChecked: false };
      });
      return arr;
    }
  };

  // 获取职位列表
  getJobList = async () => {
    const { queryData } = this.state;
    let jobsList = [];
    // 处理拼参
    let paramStr = '';
    queryData.forEach((item) => {
      if (item.ids.length > 0) {
        item.ids.forEach((i) => {
          if (i) paramStr = `${item.type}_in=${i}&${paramStr}`;
        });
      }
    });
    paramStr = paramStr.slice(0, paramStr.length - 1);
    let url = paramStr ? `/jobs?${paramStr}` : `/jobs`;

    let res = await request({ method: 'get', url });
    if (res && res.length > 0) {
      jobsList = res.map((item) => {
        return {
          jobId: item.id,
          jobName: item.title,
          jobMoney: item.salary,
          jobAddress: item.job_site.name,
          jobRecord: item.education,
          jobYears: item.job_experience.name,
          jobType: item.job_type.name,
          jobDescription: item.description,
        };
      });
    }
    this.setState({
      jobsList,
    });

    /* dom是根据以下数据结构渲染 */
    // jobsList: [
    //   {
    //     jobId: '1',
    //     jobName: '高级临床项目经理',
    //     jobMoney: '40-50K',
    //     jobAddress: '北京-东城区',
    //     jobRecord: '统招本科',
    //     jobYears: '经验不限',
    //     jobType: '全职应届生',
    //     jobDescription: ' ',
    //   }
    // ],
  };

  clickDetails = (jobId, type) => {
    const { jobsList } = this.state;
    let newData = jobsList.map((item) => {
      if (type === 'open' && item.jobId === jobId) {
        item.open = true;
      } else if (type === 'close' && item.jobId === jobId) {
        item.open = false;
      }
      return item;
    });
    this.setState({
      jobsList: newData,
    });
  };

  // 查询
  handleKewords = (id, type) => {
    // console.log(id, type);
    const { queryData } = this.state;
    let newQueryData = queryData.map((item) => {
      if (item.type === type) {
        // 如果ids中已包含当前选择，标识已选中，则取消选择
        if (item.ids.includes(id)) {
          let ids = item.ids.filter((i) => {
            return i !== id;
          });
          return { ids, type };
        } else {
          return { ids: [...item.ids, id], type };
        }
      } else {
        return item;
      }
    });
    this.setState(
      {
        queryData: newQueryData,
      },
      () => {
        this.getJobList();
      },
    );
  };

  // 移动端下拉框选择
  selectChange = (selectedOption) => {
    const { queryData } = this.state;
    let newQueryData = queryData.map((item) => {
      if (item.type === selectedOption.type) {
        return { ids: [selectedOption.value], type: selectedOption.type };
      } else {
        return item;
      }
    });
    // console.log(newQueryData);
    this.setState(
      {
        queryData: newQueryData,
      },
      () => {
        this.getJobList();
      },
    );
  };

  // 处理成移动端筛选数据格式
  handleForMobile = (data) => {
    if (data && document.body.clientWidth < 1025) {
      const { jobExperiences, jobSites } = data;
      let newSelectData = [];
      if (jobExperiences && jobExperiences.length > 0) {
        const itemList = jobExperiences.map((item) => {
          return { label: item.name, value: item.id, type: 'job_experience' };
        });

        itemList.unshift({
          value: '',
          label: `全部经验`,
          type: 'job_experience',
        });
        let bigObj = {
          typeTitle: '经验',
          itemList: itemList,
        };
        newSelectData.push(bigObj);
      }
      if (jobSites && jobSites.length > 0) {
        const itemList = jobSites.map((item) => {
          return { label: item.name, value: item.id, type: 'job_site' };
        });

        itemList.unshift({
          value: '',
          label: `全部地点`,
          type: 'job_site',
        });
        let bigObj = {
          typeTitle: '地点',
          itemList: itemList,
        };

        newSelectData.push(bigObj);
      }
      this.setState({
        selectDataForMobile: newSelectData,
      });
    }
  };

  // 点击职位申请按钮
  applyJob = (jobId) => {
    console.log(jobId);
    console.log(this.props, '&&&&');
    this.props.history.push(`/applyJob/${jobId}`);
  };

  render() {
    const { jobExperiences, jobPositions, jobTypes, jobSites, jobsList, selectDataForMobile } = this.state;
    return (
      <div className={styles.message}>
        {/* <img src={jobLogo} alt="" className={styles.jobLogo} /> */}

        {/* 移动端---选择区 */}
        <div className={styles.select_content}>
          {selectDataForMobile &&
            selectDataForMobile.length > 0 &&
            selectDataForMobile.map((list, index) => {
              return (
                <div className={styles.select_item} key={`select${index}`}>
                  <Select
                    options={list.itemList}
                    placeholder={`${list.itemList[0].label}`}
                    onChange={this.selectChange}
                    key={`select${index}`}
                  />
                </div>
              );
            })}
        </div>
        <div className={styles.message_content}>
          {/* 招聘区 */}
          <div className={styles.message_content_infor}>
            {jobsList.length > 0 ? (
              jobsList.map((item, index) => {
                return (
                  <div className={styles.content_infor_item} key={`jobs${index}`}>
                    {item.open ? (
                      <div className={styles.open_details} onClick={this.clickDetails.bind(this, item.jobId, 'close')}>
                        <span className={styles.open_details_grary}>
                          {/* 收起详情<i className={'iconfont ' + ' ' + styles.icon_jiantou}>&#xe664;</i> */}
                          收起详情
                        </span>
                        <i className={`iconfont ${styles.icon_jiantou} ${styles.iconSize}`} style={{ color: '#999' }}>
                          &#xe664;
                        </i>
                      </div>
                    ) : (
                      <div className={styles.open_details} onClick={this.clickDetails.bind(this, item.jobId, 'open')}>
                        展开详情
                        {/* <i className={'iconfont' + styles.icon_jiantou + styles.iconSize}>&#xe665;</i> */}
                        <i className={`iconfont ${styles.icon_jiantou} ${styles.iconSize}`}>&#xe665;</i>
                      </div>
                    )}
                    <div className={styles.infor_title}>{item.jobName || '-'}</div>
                    <div className={styles.infor_five}>
                      <div className={styles.infor_salary}>{item.jobMoney || '-'}</div>
                      <div className={styles.infor_others}>{item.jobAddress || '-'}</div>
                      <div className={styles.infor_others}>{item.jobRecord || '-'}</div>
                      <div className={styles.infor_others}>{item.jobYears || '-'}</div>
                      <div className={styles.infor_others}>{item.jobType || '-'}</div>
                    </div>

                    <div style={{ paddingLeft: '7.7%', display: item.open ? 'block' : 'none', paddingTop: '2%' }}>
                      <div className={styles.infor_description_detail}>
                        <ReactMarkdown children={item.jobDescription} />
                      </div>

                      <div className={styles.apply_job_button} onClick={this.applyJob.bind(this, item.jobId)}>
                        职位申请
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              // <div className={styles.no_data}>暂无数据</div>
              <div className={styles.no_data}>
                <img src={noData} alt="" />
              </div>
            )}
          </div>
          {/* PC端---右侧选择内容区 */}
          <div className={styles.message_content_choose}>
            <div className={styles.message_content_choose_item}>
              <div>
                <div className={styles.choose_post_title}>职位职能</div>
                {jobPositions.length > 0 &&
                  jobPositions.map((item, index) => {
                    return (
                      <div className={styles.checkbox_text} key={`checkbox${index}`}>
                        <input
                          onChange={this.handleKewords.bind(this, item.id, 'job_position')}
                          type="checkbox"
                          value=""
                          defaultChecked={item.isChecked}
                        />
                        <span className={styles.checkbox_name} key={index}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
              <div>
                <div className={styles.choose_post_title}>在职类型</div>
                {jobTypes.length > 0 &&
                  jobTypes.map((item, index) => {
                    return (
                      <div className={styles.checkbox_text} key={`checkbox${index}`}>
                        <input
                          onChange={this.handleKewords.bind(this, item.id, 'job_type')}
                          type="checkbox"
                          value=""
                          defaultChecked={item.isChecked}
                        />
                        <span className={styles.checkbox_name} key={index}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
              <div>
                <div className={styles.choose_post_title}>经验要求</div>
                {jobExperiences.length > 0 &&
                  jobExperiences.map((item, index) => {
                    return (
                      <div className={styles.checkbox_text} key={`checkbox${index}`}>
                        <input
                          onChange={this.handleKewords.bind(this, item.id, 'job_experience')}
                          type="checkbox"
                          value=""
                          defaultChecked={item.isChecked}
                        />
                        <span className={styles.checkbox_name} key={index}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
              </div>

              <div>
                <div className={styles.choose_post_title}>工作地点</div>
                {jobSites.length > 0 &&
                  jobSites.map((item, index) => {
                    return (
                      <div className={styles.checkbox_text} key={`checkbox${index}`}>
                        <input
                          onChange={this.handleKewords.bind(this, item.id, 'job_site')}
                          type="checkbox"
                          value=""
                          defaultChecked={item.isChecked}
                        />
                        <span className={styles.checkbox_name} key={index}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecruitJobPart;
