import React from 'react';
import classNames from 'classnames';
import codeImg from '../../images/code.png';
import request from '../../utils/request';
import styles from './index.module.less';
import Header from '../../components/Header';
import PhoneMenu from '../../components/PhoneMenu';
import closeImg from '../../images/close.png';

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonShow: true,
      name: '',
      company: '',
      phone: '',
      email: '',
      content: '',
      type: '',
      newList: [],
      checkBox: [
        { checked: false, name: '数据授权' },
        { checked: false, name: '真实世界研究' },
        { checked: false, name: '产品服务' },
      ],
      learnTypes: [
        { checked: false, name: '数据授权' },
        { checked: false, name: '真实世界研究' },
        { checked: false, name: '产品服务' },
      ],
      regFailText: '',
      showContactUsModal: false,
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  touchToUs = () => {
    this.props.history.push(`/contactus`);
  };
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      console.log('小于');
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    console.log('jumpToContactUsPage');
    this.setState({
      showContactUsModal: false,
    });
  };
  // 产品服务
  jumpToServicePage = () => {
    console.log('jumpToServicePage');
    // this.setState({
    //   showContactUsModal: false,
    // });
    this.props.history.push(`/service`);
  };
  clickHeaderService = () => {
    this.props.history.push(`/service`);
  };
  clickFooterIndex = () => {
    this.props.history.push(`/`);
  };
  clickGoBack = () => {
    this.props.history.push(`/`);
  };
  // 关闭按钮
  closeCurrentPage = () => {
    this.props.history.go(-1);
  };
  clickHeaderJob = () => {
    // this.props.history.push(`/recruit`);
  };
  jumpToJobPage = () => {
    // const w = window.open('about:blank');
    window.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };

  // -手机号码校验
  regTel = (tel) => {
    let reg = /^\d{11}$/;
    let result = reg.test(tel);
    return result;
  };
  // -邮箱校验
  regMail = (str) => {
    let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    let result = reg.test(str);
    return result;
  };
  // -去左右空格;
  trim = (s) => {
    return s.replace(/(^\s*)|(\s*$)/g, '');
  };
  // let newList = [];
  clickCheckBoxItem = (val) => {
    // console.log(e.target.value, '测试多选框');
    // let newList = this.state.newList;
    // if (newList.indexOf(e.target.value) > -1) {
    //   console.log('存在');
    //   let index = newList.indexOf(e.target.value);

    //   newList.splice(index, 1);
    // } else {
    //   console.log('不存在');
    //   newList.push(e.target.value);
    // }
    // console.log(newList.join(), '***newList***');
    // this.setState({
    //   newList,
    // });

    // let learnTypes = JSON.parse(JSON.stringify(this.state.learnTypes));

    // learnTypes[val].checked = !learnTypes[val].checked;
    // this.setState({
    //   learnTypes: learnTypes,
    // });
    let checkBox = JSON.parse(JSON.stringify(this.state.checkBox));
    checkBox[val].checked = !checkBox[val].checked;
    this.setState({
      checkBox: checkBox,
    });
  };

  handleKewords = (value, event) => {
    console.log(value, '******');
  };

  // -提交表单
  submit = () => {
    this.setState(
      {
        regFail: false,
        regFailText: '',
      },
      () => {
        let { checkBox } = this.state;

        let name = this.trim(this.state.name);
        let phone = this.trim(this.state.phone);
        let company = this.trim(this.state.company);
        let content = this.trim(this.state.content);
        let email = this.trim(this.state.email);
        // 必填项非空校验
        if (!name.length > 0) {
          this.setState({
            regFail: true,
            regFailText: '姓名不能为空！',
          });
          return;
        }
        if (!company.length > 0) {
          this.setState({
            regFail: true,
            regFailText: '企业名称不能为空！',
          });
          return;
        }
        if (!phone.length > 0) {
          this.setState({
            regFail: true,
            regFailText: '手机号码不能为空！',
          });
          return;
        }

        // 校验手机号码
        if (!this.regTel(phone)) {
          console.log('phone校验不通过');
          this.setState({
            regFail: true,
            regFailText: '请输入正确的手机号码！',
          });
          return;
        }
        if (email.length > 0) {
          // 校验电子邮箱
          if (!this.regMail(email)) {
            this.setState({
              regFail: true,
              regFailText: '请输入正确的电子邮箱！',
            });
            return;
          }
        }

        let newType = [];
        checkBox.forEach((item) => {
          if (item.checked) newType.push(item.name);
        });
        let params = {
          name,
          phone,
          company,
          content,
          email,
          type: newType.join(','),
        };
        this.setState(
          {
            buttonShow: false,
          },
          () => {
            if (!this.state.buttonShow) {
              request
                .post('/contacts', params)
                .then((res) => {
                  if (res) {
                    alert('信息提交成功！');
                    this.props.history.go(-1);
                  } else {
                    alert('信息提交失败！');
                  }
                })
                .catch((error) => {
                  alert('信息提交失败！');
                });
            }
          },
        );

        // axios({
        //   method: 'post',
        //   url: 'https://test.cwdata.com:6742/contacts',
        //   headers: { 'Content-Type': 'application/json' },
        //   data: { ...params },
        // })
        //   .then((res) => {
        //     console.log('成功res', res);
        //     if (res.status === 200) {
        //       alert('信息提交成功！');
        //       this.props.history.go(-1);
        //     } else {
        //       alert('信息提交失败！');
        //     }
        //   })
        //   .catch((error) => {
        //     alert('信息提交失败！');
        //   });
      },
    );
  };

  // ------------------------- 页面 --------------------------
  render() {
    const { showContactUsModal, checkBox, buttonShow } = this.state;
    return (
      <div className={styles.effectBox}>
        <div className={styles.headBox}>
          <Header
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
          />
          {showContactUsModal && (
            <PhoneMenu
              closePhoneMenuModal={this.closePhoneMenuModal}
              jumpToContactUsPage={this.jumpToContactUsPage}
              jumpToServicePage={this.jumpToServicePage}
              jumpToJobPage={this.jumpToJobPage}
            />
          )}
        </div>

        <div className={styles.contactUsPage}>
          <div>
            <img src={closeImg} alt="" className={styles.closeImg} onClick={this.closeCurrentPage.bind(this)} />
          </div>
          {/* // -左侧漂浮部分 */}
          <div className={styles.leftInfo}>
            <div className={styles.leftInfo_title}>业务咨询与客户服务</div>
            <div className={styles.leftInfo_item}>
              <div className={styles.modalContentLeft_title}>电话：</div>
              <div className={styles.modalContentLeft_content}>010-53395362</div>
            </div>
            <div className={styles.leftInfo_item}>
              <div className={styles.modalContentLeft_title}>邮箱：</div>
              <div className={styles.modalContentLeft_content}>info@cwdata.com</div>
            </div>
            <div className={styles.modalContentLeft_midLine} />
            {/* <div className={styles.leftInfo_item}>
              <div className={styles.modalContentLeft_title}>办公地址：</div>
              <span className={styles.modalContentLeft_content}>北京市东城区王府井大街219号淘汇新天5层</span>
            </div> */}
            <div className={styles.leftInfo_code}>
              <img src={codeImg} alt="" />
              <div className={styles.modalContentRight_content} style={{ paddingLeft: '24px' }}>
                公众号
              </div>
            </div>
          </div>
          {/* // -顶部文字描述 */}
          <div className={styles.contactUsTitle}>
            <div className={styles.contactUsTitle_main}>联系我们</div>
            <div className={styles.contactUsTitle_sub}>
              <div>您可以通过电话与邮件和我们聊一聊。</div>
              <div>也可以填写下方问询表，我们将在3个工作日内与您取得联系。</div>
            </div>
            <div className={styles.contactUsTitleSubBox}>
              <div className={styles.contactUsTitleSubBox_title}>业务咨询与客户服务</div>
              <div className={styles.contactUsTitleSubBox_info}>
                <span>座机 010-53395362</span>
                <span>|</span>
                <span>邮箱 info@cwdata.com</span>
              </div>
            </div>
          </div>
          {/* // - 主体-表单部分 */}
          <div className={styles.contactUsForm}>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>您的姓名*</div>
              <input
                type="text"
                placeholder="请输入"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>企业名称*</div>
              <input
                type="text"
                placeholder="请输入"
                value={this.state.company}
                onChange={(e) => this.setState({ company: e.target.value })}
              />
            </div>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>手机号码*</div>
              <input
                type="text"
                placeholder="请输入"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>邮箱地址</div>
              <input
                type="text"
                placeholder="请输入"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>您需要哪方面的帮助</div>
              <textarea
                type="text"
                placeholder="请输入"
                value={this.state.content}
                onChange={(e) => this.setState({ content: e.target.value })}
              />
            </div>
            <div className={styles.contactUsForm_item}>
              <div className={styles.contactUsForm_itemName}>您希望进一步了解</div>
              {/* 
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {learnTypes.length > 0 &&
                  learnTypes.map((item, index) => {
                    return (
                      <div
                        className={styles.checkbox_text}
                        key={`learnTypes${index}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <input
                          onChange={this.clickCheckBoxItem.bind(this)}
                          type="checkbox"
                          value={item.name}
                          defaultChecked={item.isChecked}
                          className={styles.learnInput}
                        />
                        <span className={styles.learnCheckbox_name} key={index}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
              </div> */}
              <div className={styles.contactUs_box}>
                <div
                  className={styles.contactUsForm_checkboxItem}
                  onClick={() => {
                    this.clickCheckBoxItem(0);
                  }}
                >
                  <span
                    className={styles.contactUsForm_checkboxItem_box}
                    style={{
                      background: checkBox[0].checked ? 'rgb(0,117,255)' : ' ',
                      border: checkBox[0].checked
                        ? '2px solid rgba(0, 117, 255, 0.5)'
                        : ' 2px solid rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    {checkBox[0].checked ? '✓' : ' '}
                  </span>
                  &nbsp;&nbsp;
                  <span className={styles.contactUsForm_checkboxItem_text}>数据授权</span>
                </div>

                <div
                  className={styles.contactUsForm_checkboxItem}
                  onClick={() => {
                    this.clickCheckBoxItem(1);
                  }}
                >
                  <span
                    className={styles.contactUsForm_checkboxItem_box}
                    style={{
                      background: checkBox[1].checked ? 'rgb(0,117,255)' : ' ',
                      border: checkBox[1].checked
                        ? '2px solid rgba(0, 117, 255, 0.5)'
                        : ' 2px solid rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    {checkBox[1].checked ? '✓' : ' '}
                  </span>
                  &nbsp;&nbsp;
                  <span className={styles.contactUsForm_checkboxItem_text}>真实世界研究</span>
                </div>
                <div
                  className={styles.contactUsForm_checkboxItem}
                  onClick={() => {
                    this.clickCheckBoxItem(2);
                  }}
                >
                  <span
                    className={styles.contactUsForm_checkboxItem_box}
                    style={{
                      background: checkBox[2].checked ? 'rgb(0,117,255)' : ' ',
                      border: checkBox[2].checked
                        ? '2px solid rgba(0, 117, 255, 0.5)'
                        : ' 2px solid rgba(255, 255, 255, 0.5)',
                    }}
                  >
                    {checkBox[2].checked ? '✓' : ' '}
                  </span>
                  &nbsp;&nbsp;
                  <span className={styles.contactUsForm_checkboxItem_text}>产品服务</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.contactUs_submitBox)}>
            <div className={classNames(styles.contactUs_submitRegFailNotice)}>{this.state.regFailText}</div>
            {buttonShow ? (
              <div
                className={classNames(styles.contactUs_submit)}
                onClick={() => {
                  this.submit();
                }}
              >
                发 送
              </div>
            ) : (
              <div className={classNames(styles.contactUs_submit)}> 发 送</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsPage;
