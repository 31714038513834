import React, { Component } from 'react';
import styles from './index.module.less';
import poleStar1 from '../../images/poleStar/Polestar_cun.png';
import poleStar2 from '../../images/poleStar/Polestar_lian.png';
import poleStar3 from '../../images/poleStar/Polestar_rise.png';

import Footer from './../../component/Footer/';

export class PoleStar extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPageBottom: 'polestar' };
  }
  login() {
    window.location.href = 'http://192.168.100.27:8080/login';
  }
  render() {
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <div className={styles.header_img} />
          <div className={styles.header_text} onClick={this.login.bind(this)}>
            快速登录
          </div>
        </div>
        <div className={styles.bgContent}>
          <div className={styles.bgContent_text}>
            完善的<span style={{ fontWeight: 'bold' }}>数字医疗器械云上解决方案</span>，全面支撑数字医疗器械技术创新
          </div>
          <div className={styles.bgContent_button}>
            <div className={styles.bgContent_button_img} />
            <div className={styles.bgContent_button_text}>联系我们</div>
          </div>
          <div className={styles.ball_1} />
          <div className={styles.ball_2} />
          <div className={styles.ball_3} />
          <div className={styles.cloud} />
          <div className={styles.wifi} />
          <div className={styles.pattern} />
        </div>

        <div className={styles.content}>
          <div className={styles.content_item}>
            <div className={styles.content_item_img}>
              <img src={poleStar1} alt="" />
            </div>
            <div className={styles.content_item_text}>
              安全云存储服务，立足 PKS
              自主可控技术体系构建的生物样本数据存储服务，消除数据离岸、数据存储安全风险，同时提供数据归档及永久存储服务。
            </div>
          </div>
          <div className={styles.content_item}>
            <div className={styles.content_item_img}>
              <img src={poleStar2} alt="" />
            </div>
            <div className={styles.content_item_text}>
              远程设备维护及 OTA 升级服务，基于物联网设备管理架构设计赋能数字医疗器械，实现设备远程诊断、OTA 升级支持。
            </div>
          </div>
          <div className={styles.content_item}>
            <div className={styles.content_item_img}>
              <img src={poleStar3} alt="" />
            </div>
            <div className={styles.content_item_text}>
              模型联合开发及模型训练服务，依托中电数据海量数据资源和汇智康行 AI 开发能力，结合模型训练，实现数字医疗器械
              AI 赋能，推动产品、技术升级。
            </div>
          </div>
        </div>
        <div className={styles.tip}>
          <div className={styles.tip_one}>深入了解北斗云的医疗器械相关服务</div>
          <div className={styles.tip_two}>和我们聊一聊</div>
        </div>
        <Footer currentPageBottom={this.state.currentPageBottom} />
      </div>
    );
  }
}

export default PoleStar;
