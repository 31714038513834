import React from 'react';
// import Header from './../../components/Header/';
import arrow from './../../images/homePage/arrow.svg';
import styles from './index.module.less';
import 'animate.css';
class IntroPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceType: '', //  设备类型：phone（0-1024），pad（1025-1440），pc（1441-）
    };
  }
  // ------------------------------------------- 生命周期 ----------------------------------------------
  componentDidMount() {
    // 获得屏幕宽度类型
    // this.setState({ deviceType: this.getClientWidth() });
  }
  // --------------------------------------------- 方法 ------------------------------------------------
  // .获得屏幕宽度类型
  getClientWidth() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 0 && clientWidth <= 1024) return 'phone';
    else if (clientWidth >= 1025 && clientWidth <= 1440) return 'pad';
    else if (clientWidth >= 1441) return 'pc';
  }
  // .展示下一页
  clickNextPage() {
    this.props.clickNextPage(1);
    // this.props.history.push(`/page1`);
  }
  // --------------------------------------------- 页面 ------------------------------------------------
  render() {
    const { animateShow } = this.props;
    const active = animateShow
      ? ['animate__animated', 'animate__fadeIn', styles.leftInner]
      : ['animate__animated', 'animate__fadeOut', styles.leftInner];
    // const btnActive = animateShow
    //   ? ['animate__animated', 'animate__headShake', 'animate__infinite	infinite', styles.leftInner]
    //   : ['animate__animated', 'animate__fadeOut', styles.leftInner];
    return (
      <div className={styles.IntroPage}>
        {/* 首页-介绍页 */}
        <div>
          <div className={active.join(' ')}>
            <div className={styles.bodyText}>国家生命科学领域技术升级的核心力量和组织平台</div>
            <div className={styles.bodyTags}>
              <span>数据点亮医疗 | 技术守护健康</span>
            </div>
          </div>
          <div className={active.join(' ')}>
            <div className={styles.bodySlider} onClick={() => this.clickNextPage()}>
              {/* <div>滑动继续了解</div> */}
              {/* <img src={arrow} style={{ width: '12px', padding: '10px' }} alt="" /> */}
            </div>
          </div>
        </div>
        {/* 底部-备案信息（手机端不展示） */}
        <div className={styles.bodyFooter}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ cursor: 'pointer', marginRight: 30 }}
              onClick={() => {
                window.open('https://beian.miit.gov.cn/');
              }}
            >
              京ICP备2023008225号-1
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006455');
              }}
            >
              京公网安备 11011502006455号
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntroPage;
