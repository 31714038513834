import React, { Component } from 'react';
import Header from '../../components/Header';
import PhoneMenu from '../../components/PhoneMenu';
import request from '../../utils/request';
import { Upload, message, Button } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';
import styles from './index.module.less';
import closeImg from '../../images/close.png';

export class ApplyJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonShow: true,
      showContactUsModal: false,
      name: '',
      number: '',
      email: '',
      schoolName: '',
      subjectName: '',
      fileValue: '',
      fileName: '',
      regFailText: '',
      selectedId: '',
      fileId: '',
      loading: false,
      imageUrl: false,
      checkBox: [
        { checked: false, name: '全职' },
        { checked: false, name: '应届生' },
        { checked: false, name: '实习生' },
        { checked: false, name: '在校生' },
      ],
      optionsEduction: [],
      valueEduction: '',
      optionsJob: [],
      valueJob: '',
      jobTypes: [
        {
          name: '全职',
          value: 1,
          isck: false,
        },
        {
          name: '实习生',
          value: 2,
          isck: false,
        },
        {
          name: '应届生',
          value: 3,
          isck: false,
        },
        {
          name: '在校生',
          value: 4,
          isck: false,
        },
      ],
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
    console.log(this.props, '^^^^');
    console.log(this.props.match.params.id, '^^^^');
    this.setState({
      valueJob: this.props.match.params.id,
    });
    this.getEducations();
    this.getJobList();
  }

  // 获取职位列表
  getJobList = async () => {
    let res = await request.get('/jobs');
    console.log(res, 'res');

    if (res && res.length > 0) {
      let itemList = res.map((item) => {
        return {
          name: item.title,
          value: item.id,
        };
      });

      this.setState({
        optionsJob: itemList,
      });
    }
  };

  // 获取学历列表
  getEducations = async () => {
    let res = await request.get('/educations');
    console.log(res, 'res学历');
    console.log('成功！');
    if (res && res.length > 0) {
      let itemList = res.map((item) => {
        return {
          name: item.name,
          value: item.id,
        };
      });
      let obj = {
        name: '请选择',
        value: 0,
      };
      itemList.unshift(obj);
      this.setState({
        optionsEduction: itemList,
      });
    }

    // axios({
    //   method: 'get',
    //   url: 'https://test.cwdata.com:6742/educations',
    //   headers: { 'Content-Type': 'application/json' },
    // })
    // .then((res) => {
    //   console.log('成功res', res.data);
    //   if (res.status === 200) {
    //     console.log('成功！');

    //     if (res.data && res.data.length > 0) {
    //       let itemList = res.data.map((item) => {
    //         return {
    //           name: item.name,
    //           value: item.id,
    //         };
    //       });
    //       let obj = {
    //         name: '请选择',
    //         value: 0,
    //       };
    //       itemList.unshift(obj);
    //       this.setState({
    //         optionsEduction: itemList,
    //       });
    //     }
    //   } else {
    //     console.log('失败！');
    //   }
    // })
    // .catch((error) => {
    //   console.log('失败！');
    // });
  };

  // 姓名的监听事件
  changeName = (e) => {
    let value = e.target.value;
    this.setState({
      name: value,
    });
  };
  changeIphone = (e) => {
    let numberValue = e.target.value;
    this.setState({
      number: numberValue,
    });
  };
  changeFile = (e) => {
    this.setState({
      fileValue: e.target.files[0].name,
      fileName: e.target.files[0].name,
    });
  };

  codeVerification = (phone) => {
    let phoneCodeVerification = /^[1][3,4,5,7,8][0-9]{9}$/;
    return phoneCodeVerification.test(phone);
  };
  changeEmail = (e) => {
    let email = e.target.value.trim();
    this.setState({
      email: email,
    });
  };
  changeEducation = (e) => {
    this.setState({
      valueEduction: e.target.value,
    });
  };
  // 学校监听事件
  changeSchool = (e) => {
    this.setState({
      schoolName: e.target.value,
    });
  };
  // 专业监听事件
  changeSubject = (e) => {
    this.setState({
      subjectName: e.target.value,
    });
  };
  // 职位监听事件
  changeJob = (e) => {
    this.setState({
      valueJob: e.target.value,
    });
  };

  // 在职类型监听事件
  changeType = (index) => {
    let jobTypes = this.state.jobTypes;
    jobTypes[index].isck = !jobTypes[index].isck;
    this.setState({
      jobTypes: jobTypes,
    });
  };
  clickCheckBoxItem = (val) => {
    let checkBox = JSON.parse(JSON.stringify(this.state.checkBox));
    checkBox[val].checked = !checkBox[val].checked;
    this.setState({
      checkBox: checkBox,
    });
  };
  // -去左右空格;
  trim = (s) => {
    return s.replace(/(^\s*)|(\s*$)/g, '');
  };
  // -手机号码校验
  regTel = (tel) => {
    let reg = /^\d{11}$/;
    let result = reg.test(tel);
    return result;
  };
  // -邮箱校验
  regMail = (str) => {
    let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    let result = reg.test(str);
    return result;
  };

  // 提交按钮
  submit = async () => {
    let name = this.trim(this.state.name);
    let phone = this.trim(this.state.number);

    let email = this.trim(this.state.email);
    let education = this.state.valueEduction;
    let school = this.trim(this.state.schoolName);
    let major = this.trim(this.state.subjectName);
    let job = this.state.valueJob;
    let file = this.state.fileId;

    if (!name.length > 0) {
      this.setState({
        regFailText: '姓名不能为空！',
      });
      return;
    }
    if (!phone.length > 0) {
      this.setState({
        regFailText: '手机号码不能为空！',
      });
      return;
    }
    // 校验手机号码
    if (!this.regTel(phone)) {
      console.log('phone校验不通过');
      this.setState({
        regFailText: '请输入正确的手机号码！',
      });
      return;
    }
    if (email.length > 0) {
      // 校验电子邮箱
      if (!this.regMail(email)) {
        this.setState({
          regFailText: '请输入正确的电子邮箱！',
        });
        return;
      }
    } else {
      this.setState({
        regFailText: '邮箱地址不能为空！',
      });
      return;
    }

    // 学历
    if (education === '' || education === '0') {
      this.setState({
        regFailText: '请选择最高学历！',
      });
      return;
    }
    if (!school.length > 0) {
      this.setState({
        regFailText: '学校不能为空！',
      });
      return;
    }

    if (!major.length > 0) {
      this.setState({
        regFailText: '专业不能为空！',
      });
      return;
    }

    if (file === '') {
      this.setState({
        regFailText: '请上传简历！',
      });
      return;
    }
    let params = {
      name,
      phone,
      email,
      school,
      major,
      job,
      file,
      education,
    };
    this.setState({
      buttonShow: false,
    });
    let res = await request.post('/resumes', params);
    if (res) {
      message.success('信息提交成功！');
      this.props.history.go(-1);
    } else {
      message.error('信息提交失败！');
    }

    // axios({
    //   method: 'post',
    //   url: 'https://test.cwdata.com:6742/resumes',
    //   headers: { 'Content-Type': 'application/json' },
    //   data: params,
    // })
    //   .then((res) => {
    //     console.log('成功res', res);
    //     if (res.status === 200) {
    //       message.success('信息提交成功！');
    //       this.props.history.go(-1);
    //     } else {
    //       message.error('信息提交失败！');
    //     }
    //   })
    //   .catch((error) => {
    //     message.error('信息提交失败！');
    //   });
  };
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  clickHeaderService = () => {
    this.props.history.push(`/service`);
  };
  clickGoBack = () => {
    this.props.history.push(`/`);
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    this.props.history.push(`/contactus`);
  };
  // 产品服务
  jumpToServicePage = () => {
    this.props.history.push(`/service`);
  };
  clickHeaderJob = () => {
    // this.props.history.push(`/recruit`);
  };
  jumpToJobPage = () => {
    // this.props.history.push(`/recruit`);
  };
  // 关闭按钮
  closeCurrentPage = () => {
    this.props.history.go(-1);
  };
  onChangeUploadFile = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功！`);
      this.setState({
        fileId: info.fileList[0].response[0].id,
      });
    }
    if (info.file.status === 'error') {
      console.log('上传文件为空');
      message.error(`${info.file.name} 上传错误，请检查文件内容`);
    }
    if (info.file.status === 'remove') {
      console.log('删除');
      this.setState({
        fileId: '',
      });
    }
  };

  render() {
    const uploadProps = {
      name: 'files',
      action: '/api/upload/',
      // action: '/upload/',

      beforeUpload(file, fileList) {
        const uploadFile = file.type;
        let types = [
          'image/png',
          'image/jpeg',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
        ];
        if (!types.includes(file.type)) {
          message.error(`${file.name} 上传简历的文件格式不正确，请重新按要求格式上传`);
          return false;
        }
        return uploadFile;
      },
    };
    const { optionsEduction, valueEduction, optionsJob, valueJob, fileName } = this.state;

    const { showContactUsModal, buttonShow } = this.state;

    return (
      <div className={styles.page} style={{ boxSizing: 'border-box !important' }}>
        <div style={{ position: 'fixed', width: '100%', zIndex: '99' }}>
          <Header
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
          />
          {showContactUsModal && (
            <PhoneMenu
              closePhoneMenuModal={this.closePhoneMenuModal}
              jumpToContactUsPage={this.jumpToContactUsPage}
              jumpToServicePage={this.jumpToServicePage}
              jumpToJobPage={this.jumpToJobPage}
            />
          )}
        </div>

        <div className={styles.content}>
          <div>
            <img src={closeImg} alt="" className={styles.closeImg} onClick={this.closeCurrentPage.bind(this)} />
          </div>
          <div className={styles.content_head}>职位申请</div>
          <div className={styles.content_title}>如简历符合职位要求，我们会在3个工作日内与您取得联系。</div>
          <div className={styles.content_message}>
            <div className={styles.name}>您的姓名*</div>
            <input
              className={styles.input_name}
              type="text"
              placeholder="请输入"
              onChange={this.changeName}
              value={this.state.name}
            />

            <div className={styles.name}>手机号码*</div>
            <input
              className={styles.input_name}
              type="text"
              placeholder="请输入"
              onChange={this.changeIphone}
              value={this.state.number}
            />
            <div className={styles.name}>邮箱地址*</div>
            <input
              className={styles.input_name}
              type="text"
              placeholder="请输入"
              onChange={this.changeEmail}
              value={this.state.email}
            />
            <div className={styles.name}>最高学历*</div>
            <select
              name=""
              id=""
              onChange={this.changeEducation}
              value={valueEduction}
              style={{ boxSizing: 'border-box !important' }}
            >
              {optionsEduction &&
                optionsEduction.map((item, index) => {
                  return (
                    <option key={item.name} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}

              {/* <option value="2">硕士</option> */}
            </select>
            <div className={styles.name}>学校*</div>
            <input
              className={styles.input_name}
              type="text"
              placeholder="请输入"
              onChange={this.changeSchool}
              value={this.state.schoolName}
            />
            <div className={styles.name}>专业*</div>
            <input
              className={styles.input_name}
              type="text"
              placeholder="请输入"
              onChange={this.changeSubject}
              value={this.state.subjectName}
            />
            <div className={styles.name}>您申请的职位*（系统带入）</div>
            <select
              name=""
              id=""
              onChange={this.changeJob}
              value={valueJob}
              style={{ boxSizing: 'border-box !important' }}
            >
              {optionsJob &&
                optionsJob.map((item, index) => {
                  return (
                    <option key={item.name} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              {/* <option value="">高级临床项目经理</option> */}
            </select>
            <div className={styles.name}>上传简历*</div>

            {/* antd插件 */}

            <div className={styles.pc_upload}>
              <Upload {...uploadProps} maxCount={1} onChange={this.onChangeUploadFile}>
                <Button icon={<UploadOutlined />}>上传简历</Button>
              </Upload>
            </div>

            <div className={styles.iphone_upload}>
              <Upload {...uploadProps} maxCount={1} onChange={this.onChangeUploadFile}>
                <Button
                  icon={<PlusOutlined />}
                  style={{ width: '1.5rem', height: '1.5rem', background: '#004ea2', color: '#fff' }}
                />
              </Upload>
            </div>

            <div className={styles.choose_FileName}>{fileName}</div>
            <div className={styles.small_tip_file}>支持扩展名：.doc .docx .pdf .jpg .png</div>
          </div>

          {/* *提交按钮 */}
          <div className={styles.contactUs_submitRegFailNotice}>{this.state.regFailText}</div>
          {buttonShow ? (
            <div
              className={styles.send}
              onClick={() => {
                this.submit();
              }}
            >
              提交
            </div>
          ) : (
            <div className={styles.send}>提交</div>
          )}
        </div>
      </div>
    );
  }
}

export default ApplyJob;
